// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/primitives/Select.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$(),
  _s3 = $RefreshSig$(),
  _s4 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/primitives/Select.tsx");
  import.meta.hot.lastModified = "1718370225243.7095";
}
// REMIX HMR END

import * as Ariakit from "@ariakit/react";
import { SelectValue } from "@ariakit/react-core/select/select-value";
import { Link } from "@remix-run/react";
import * as React from "react";
import { Fragment, useMemo, useState } from "react";
import { useShortcutKeys } from "~/hooks/useShortcutKeys";
import { cn } from "~/utils/cn";
import { ShortcutKey } from "./ShortcutKey";
import { ChevronDown } from "lucide-react";
import { matchSorter } from "match-sorter";
const sizes = {
  small: {
    button: "h-6 rounded text-xs px-2 "
  },
  medium: {
    button: "h-8 rounded text-xs px-3 text-sm"
  }
};
const style = {
  tertiary: {
    button: "bg-tertiary focus-within:ring-charcoal-500 border border-tertiary hover:text-text-bright hover:border-charcoal-600"
  },
  minimal: {
    button: "bg-transparent focus-within:ring-charcoal-500 hover:bg-tertiary disabled:bg-transparent disabled:pointer-events-none"
  }
};
const variants = {
  "tertiary/small": {
    button: cn(sizes.small.button, style.tertiary.button)
  },
  "tertiary/medium": {
    button: cn(sizes.medium.button, style.tertiary.button)
  },
  "minimal/small": {
    button: cn(sizes.small.button, style.minimal.button)
  },
  "minimal/medium": {
    button: cn(sizes.medium.button, style.minimal.button)
  }
};
function isSection(data) {
  const firstItem = data[0];
  return firstItem.type === "section" && firstItem.items !== undefined && Array.isArray(firstItem.items);
}
export function Select({
  children,
  icon,
  text,
  placeholder,
  value,
  setValue,
  defaultValue,
  label,
  heading,
  showHeading = false,
  items,
  filter,
  empty = null,
  variant = "tertiary/small",
  open,
  setOpen,
  shortcut,
  allowItemShortcuts = true,
  disabled,
  clearSearchOnSelection = true,
  dropdownIcon,
  ...props
}) {
  _s();
  const [searchValue, setSearchValue] = useState("");
  const searchable = items !== undefined && filter !== undefined;
  const matches = useMemo(() => {
    if (!items) return [];
    if (!searchValue || !filter) return items;
    if (typeof filter === "function") {
      if (isSection(items)) {
        return items.map(section => ({
          ...section,
          items: section.items.filter(item => filter(item, searchValue, section.title))
        })).filter(section => section.items.length > 0);
      }
      return items.filter(item => filter(item, searchValue));
    }
    if (typeof filter === "boolean" && filter) {
      if (isSection(items)) {
        return items.map(section => ({
          ...section,
          items: matchSorter(section.items, searchValue)
        })).filter(section => section.items.length > 0);
      }
      return matchSorter(items, searchValue);
    }
    if (isSection(items)) {
      return items.map(section => ({
        ...section,
        items: matchSorter(section.items, searchValue, filter)
      })).filter(section => section.items.length > 0);
    }
    return matchSorter(items, searchValue, filter);
  }, [searchValue, items]);
  const enableItemShortcuts = allowItemShortcuts && matches.length === items?.length;
  const select = <SelectProvider open={open} setOpen={setOpen} virtualFocus={searchable} value={value} setValue={v => {
    if (clearSearchOnSelection) {
      setSearchValue("");
    }
    if (setValue) {
      setValue(v);
    }
  }} defaultValue={defaultValue}>
      {label && <SelectLabel render={typeof label === "string" ? <div>{label}</div> : label} />}
      <SelectTrigger icon={icon} variant={variant} text={text} placeholder={placeholder} shortcut={shortcut} tooltipTitle={heading} disabled={disabled} dropdownIcon={dropdownIcon} {...props} />
      <SelectPopover>
        {!searchable && showHeading && heading && <SelectHeading render={<>{heading}</>} />}
        {searchable && <ComboBox placeholder={heading} shortcut={shortcut} value={searchValue} />}

        <SelectList>
          {typeof children === "function" ? matches.length > 0 ? isSection(matches) ? <SelectGroupedRenderer items={matches} children={children} enableItemShortcuts={enableItemShortcuts} /> : children(matches, {
          shortcutsEnabled: enableItemShortcuts
        }) : empty : children}
        </SelectList>
      </SelectPopover>
    </SelectProvider>;
  if (searchable) {
    return <ComboboxProvider resetValueOnHide setValue={value => {
      React.startTransition(() => {
        setSearchValue(value);
      });
    }}>
        {select}
      </ComboboxProvider>;
  }
  return select;
}
_s(Select, "J5Ihl7xmRKfjTaz+l7W2fEtAfgo=");
_c = Select;
export function SelectTrigger({
  icon,
  variant = "tertiary/small",
  text,
  shortcut,
  tooltipTitle,
  disabled,
  placeholder,
  dropdownIcon = false,
  children,
  className,
  ...props
}) {
  _s2();
  const ref = React.useRef(null);
  useShortcutKeys({
    shortcut: shortcut,
    action: e => {
      e.preventDefault();
      e.stopPropagation();
      if (ref.current) {
        ref.current.click();
      }
    },
    disabled
  });
  const showTooltip = tooltipTitle || shortcut;
  const variantClasses = variants[variant];
  let content = "";
  if (children) {
    content = children;
  } else if (text !== undefined) {
    if (typeof text === "function") {
      content = <SelectValue>{value => <>{text(value) ?? placeholder}</>}</SelectValue>;
    } else {
      content = text;
    }
  } else {
    content = <SelectValue>
        {value => <>
            {typeof value === "string" ? value ?? placeholder : value.length === 0 ? placeholder : value.join(", ")}
          </>}
      </SelectValue>;
  }
  return <Ariakit.TooltipProvider timeout={200}>
      <Ariakit.TooltipAnchor className="button" render={<Ariakit.Select className={cn("group flex items-center gap-1 outline-offset-0 focus-within:outline-none focus-within:ring-1 disabled:cursor-not-allowed disabled:opacity-50", variantClasses.button, className)} ref={ref} {...props} />}>
        <div className="flex grow items-center gap-0.5">
          {icon && <div className="-ml-1 flex-none">{icon}</div>}
          <div className="truncate">{content}</div>
        </div>
        {dropdownIcon === true ? <ChevronDown className={cn("size-4 flex-none text-text-dimmed transition group-hover:text-text-bright group-focus:text-text-bright")} /> : !dropdownIcon ? null : dropdownIcon}
      </Ariakit.TooltipAnchor>
      {showTooltip && <Ariakit.Tooltip disabled={shortcut === undefined} className="z-40 cursor-default rounded border border-charcoal-700 bg-background-bright px-2 py-1.5 text-xs">
          <div className="flex items-center gap-2">
            <span>{tooltipTitle ?? "Open menu"}</span>
            {shortcut && <ShortcutKey className={cn("size-4 flex-none")} shortcut={shortcut} variant={"small"} />}
          </div>
        </Ariakit.Tooltip>}
    </Ariakit.TooltipProvider>;
}
_s2(SelectTrigger, "p0WdpD8v51Rl1ywjDr0h5b/96O0=", false, function () {
  return [useShortcutKeys];
});
_c2 = SelectTrigger;
export function SelectProvider(props) {
  return <Ariakit.SelectProvider {...props} />;
}
_c3 = SelectProvider;
export function ComboboxProvider(props) {
  return <Ariakit.ComboboxProvider {...props} />;
}
_c4 = ComboboxProvider;
function SelectGroupedRenderer({
  items,
  children,
  enableItemShortcuts
}) {
  let count = 0;
  return <>
      {items.map((section, index) => {
      const previousItem = items.at(index - 1);
      count += previousItem ? previousItem.items.length : 0;
      return <Fragment key={index}>
            {children(section.items, {
          shortcutsEnabled: enableItemShortcuts,
          section: {
            title: section.title,
            startIndex: count - 1,
            count: section.items.length
          }
        })}
          </Fragment>;
    })}
    </>;
}
_c5 = SelectGroupedRenderer;
export function SelectList(props) {
  _s3();
  const combobox = Ariakit.useComboboxContext();
  const Component = combobox ? Ariakit.ComboboxList : Ariakit.SelectList;
  return <Component {...props} className={cn("overflow-y-auto overscroll-contain outline-none scrollbar-thin scrollbar-track-transparent scrollbar-thumb-charcoal-600", props.className)} />;
}
_s3(SelectList, "lZt1ns/0v+oRqstClJtGGMZoaJ8=", false, function () {
  return [Ariakit.useComboboxContext];
});
_c6 = SelectList;
const selectItemClasses = "group cursor-pointer px-1 pt-1 text-xs text-text-dimmed outline-none last:pb-1";
export function SelectItem({
  icon,
  checkIcon = <Ariakit.SelectItemCheck className="size-8 flex-none text-white" />,
  shortcut,
  ...props
}) {
  _s4();
  const combobox = Ariakit.useComboboxContext();
  const render = combobox ? <Ariakit.ComboboxItem render={props.render} /> : undefined;
  const ref = React.useRef(null);
  useShortcutKeys({
    shortcut: shortcut,
    action: e => {
      e.preventDefault();
      e.stopPropagation();
      if (ref.current) {
        ref.current.click();
      }
    },
    disabled: props.disabled,
    enabledOnInputElements: true
  });
  return <Ariakit.SelectItem {...props} render={render} blurOnHoverEnd={false} className={cn(selectItemClasses, "[--padding-block:0.5rem] sm:[--padding-block:0.25rem]", props.className)} ref={ref}>
      <div className="flex h-7 w-full items-center gap-1 rounded-sm px-2 group-data-[active-item=true]:bg-tertiary">
        {icon}
        <div className="grow truncate">{props.children || props.value}</div>
        {checkIcon}
        {shortcut && <ShortcutKey className={cn("size-4 flex-none")} shortcut={shortcut} variant={"small"} />}
      </div>
    </Ariakit.SelectItem>;
}
_s4(SelectItem, "CzuoChaREU26GWIkPZiO4rAkcU4=", false, function () {
  return [Ariakit.useComboboxContext, useShortcutKeys];
});
_c7 = SelectItem;
export function SelectLinkItem({
  checkIcon = <Ariakit.SelectItemCheck className="size-8 flex-none text-white" />,
  to,
  ...props
}) {
  const render = <Link to={to} className={cn("block", selectItemClasses, props.className)} />;
  return <SelectItem {...props} render={render} blurOnHoverEnd={false} className={cn(selectItemClasses, props.className)} />;
}
_c8 = SelectLinkItem;
export function SelectButtonItem({
  checkIcon = <Ariakit.SelectItemCheck className="size-8 flex-none text-white" />,
  onClick,
  ...props
}) {
  const render = <button onClick={onClick} className={cn("block w-full text-left", selectItemClasses, props.className)} />;
  return <SelectItem {...props} render={render} blurOnHoverEnd={false} className={cn(selectItemClasses, props.className)} />;
}
_c9 = SelectButtonItem;
export function shortcutFromIndex(index, meta) {
  if (!meta.shortcutsEnabled) return;
  let adjustedIndex = index + (meta.section?.startIndex ?? 0);
  if (adjustedIndex > 9) return;
  if (adjustedIndex === 9) {
    adjustedIndex = -1;
  }
  return {
    key: String(adjustedIndex + 1)
  };
}
export function SelectSeparator(props) {
  return <div {...props} className={cn("h-px bg-charcoal-700", props.className)} />;
}
_c10 = SelectSeparator;
export function SelectGroup(props) {
  return <Ariakit.SelectGroup {...props} />;
}
_c11 = SelectGroup;
export function SelectGroupLabel(props) {
  return <Ariakit.SelectGroupLabel {...props} className={cn("flex h-[1.375rem] items-center border-b border-charcoal-700 bg-charcoal-750 px-2.5 text-xxs uppercase text-text-bright", props.className)} />;
}
_c12 = SelectGroupLabel;
export function SelectHeading({
  render,
  ...props
}) {
  return <div className="flex h-[1.375rem] flex-none cursor-default items-center gap-2 border-b border-charcoal-700 bg-charcoal-750 px-2.5 text-xxs uppercase text-text-bright">
      <Ariakit.SelectHeading render={<>{render}</>} />
    </div>;
}
_c13 = SelectHeading;
export function SelectPopover({
  gutter = 5,
  shift = 0,
  unmountOnHide = true,
  className,
  ...props
}) {
  return <Ariakit.SelectPopover gutter={gutter} shift={shift} unmountOnHide={unmountOnHide} className={cn("z-50 flex flex-col overflow-clip rounded border border-charcoal-700 bg-background-bright shadow-md outline-none animate-in fade-in-40", "min-w-[max(180px,calc(var(--popover-anchor-width)+0.5rem))]", "max-w-[min(480px,var(--popover-available-width))]", "max-h-[min(480px,var(--popover-available-height))]", "origin-[var(--popover-transform-origin)]", className)} {...props} />;
}
_c14 = SelectPopover;
//currently unstyled
export function SelectLabel(props) {
  return <Ariakit.SelectLabel {...props} />;
}
_c15 = SelectLabel;
export function ComboBox({
  autoSelect = true,
  placeholder = "Filter options",
  shortcut,
  ...props
}) {
  return <div className="flex h-9 w-full flex-none items-center border-b border-grid-dimmed bg-transparent px-3 text-xs text-text-dimmed outline-none">
      <Ariakit.Combobox autoSelect={autoSelect} render={<input placeholder={placeholder} />} className="flex-1 bg-transparent text-xs text-text-dimmed outline-none" {...props} />
      {shortcut && <ShortcutKey className={cn("size-4 flex-none")} shortcut={shortcut} variant={"small"} />}
    </div>;
}
_c16 = ComboBox;
var _c, _c2, _c3, _c4, _c5, _c6, _c7, _c8, _c9, _c10, _c11, _c12, _c13, _c14, _c15, _c16;
$RefreshReg$(_c, "Select");
$RefreshReg$(_c2, "SelectTrigger");
$RefreshReg$(_c3, "SelectProvider");
$RefreshReg$(_c4, "ComboboxProvider");
$RefreshReg$(_c5, "SelectGroupedRenderer");
$RefreshReg$(_c6, "SelectList");
$RefreshReg$(_c7, "SelectItem");
$RefreshReg$(_c8, "SelectLinkItem");
$RefreshReg$(_c9, "SelectButtonItem");
$RefreshReg$(_c10, "SelectSeparator");
$RefreshReg$(_c11, "SelectGroup");
$RefreshReg$(_c12, "SelectGroupLabel");
$RefreshReg$(_c13, "SelectHeading");
$RefreshReg$(_c14, "SelectPopover");
$RefreshReg$(_c15, "SelectLabel");
$RefreshReg$(_c16, "ComboBox");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;